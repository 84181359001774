@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .tail-layout-count {
    @apply m-auto min-h-screen sm:max-w-[31.25rem]  bg-white shadow-md lg:py-4 py-8 lg:w-full;
  }

  .tail-layout {
    @apply tail-bg m-auto w-full shadow-md sm:my-8 sm:h-[calc(100vh_-_4rem)] sm:max-w-96 sm:rounded-2xl;
  }

  .tail-btn-one {
    @apply bg-[#1A1A2C] h-10 w-full rounded-[0.625rem] border border-[var(--theme)] border-opacity-70;
  }

  @media (max-width: 640px) {
    .tail-bg-layout {
      @apply flex-1;
    }
    .tail-layout {
      @apply min-h-screen;
    }
  }

  .tail-link-active {
    @apply relative;
  }
  
  .tail-link-active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0.1875rem;
    background: #F7931A;
    @apply rounded-full w-full;
  }

  .tail-input-wrapper {
    background: #0A0D11;
    @apply rounded-md relative;
  }

  .tail-input-wrapper::after {
    content: '';
    position: absolute;
    width: calc(100% + 0.25rem);
    height: calc(100% + 0.25rem);
    top: -0.125rem;
    left: -0.125rem;
    background: #F7931A;
    @apply rounded-md -z-10;
  }

  .tail-btn-no-data {
    @apply h-10 rounded-md border-[var(--theme)] border bg-transparent !cursor-not-allowed !opacity-100;
  }

  .tail-btn-no-data span {
    @apply text-base text-[#CECFE6] font-normal;
  }

  .tail-btn-no-chat {
    @apply h-10 rounded-md border-[var(--theme)] bg-[var(--theme)] border !opacity-100;
  }

  .tail-btn-no-chat span {
    @apply text-[#9099BC] text-base font-normal;
  }

  .tail-btn-active {
    /* bg-[var(--theme)] */
    @apply h-10 rounded-md  border-none border-transparent;
    background:linear-gradient(to bottom,#008FDF 0%, #4A2692 100%);
  }

  .tail-btn-active span {
    @apply text-base text-white font-bold;
  }

  .tail-noactive {
    @apply opacity-40 !cursor-not-allowed;
  }

  .tail-bg {
    background: url(./assets/bg.png) no-repeat;
    background-size: 100%;
  }

  .tail-modal-bg {
    background: linear-gradient(to bottom, rgba(20, 26, 33, .7) 0%, rgb(2, 1, 34, .7) 100%);
  }
  .tail-bg-blur {
    @apply backdrop-blur;
  }

  .tail-text-menu {
    background:linear-gradient(to bottom, #FFFFFF 0%, #A3AADE 100%);
    background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
  }

  .tail-order-topbar-bg {
    @apply relative;
    background:linear-gradient(to bottom,#0C0D16 0%, #151B22 55%, rgba(81, 83, 231, .6) 100%);
  }

  .tail-order-topbar-bg::after {
    content: "";
    @apply absolute top-0 left-0 w-full h-full z-0 rounded-md;
    background: rgba(34, 61, 156, .3);
  }

  .tail-send-receive-card input{  
    outline:none;  
  }

  .tail-diff-base {
    @apply flex flex-col gap-3  text-[#D9D9D9] text-sm leading-6;
  }

  .tail-diff-base .title {
    @apply font-bold;
  }

  .tail-about-superior {
    background: linear-gradient(to bottom, #21284B 0%, #3B3F9F 100%);
  }

  .tail-no-default ul {
    list-style:disc
  }

  .tail-no-default ol>li {
    counter-increment: article_ol;
    position: relative;
    text-indent: 1.5em;
  }
  .tail-no-default ol>li::before {
    content: counter(article_ol);
    position: absolute;
    left: -1.5em;
    color: #008FDF;
    font-size: 1.25em;
    font-weight: 600;
    text-align: center;
    width: 1em;
  }
  .tail-no-default a {
    @apply text-[#086EA7];
  }

  .tail-no-default .counter {
    @apply ml-[1em];
  }

  .tail-no-default .marker {
    @apply font-bold;
  }
  .tail-no-default h3>label {
    color: #F9943C;
    display: block;
    font-size: 2em;
    line-height: 1.25;
  }

  .tail-no-default .counter {
    @apply text-[var(--theme)];
  }
}
:root {
  --theme: #008FDF;
  --bg-card: #022003;
  --bg: rgba(31,66,39,0.8);

  --color-1: rgb(245, 165, 36);
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
  margin: 0; 
}
input[type=number] {
  -moz-appearance:textfield;
}

*::-webkit-scrollbar {
  display: none;
}

* {
  scrollbar-width: none;
}